import { routerForBrowser } from 'redux-little-router'

const DEFAULT_TITLE = 'Personalized Literacy Program'

export const routes = {
  '/': {
    Component: 'Login',
    title: 'Sign In | ThinkCERCA',
  },
  '/users/sign_in': {
    Component: 'Login',
    title: 'Sign In | ThinkCERCA',
  },
  '/lessons/:lessonId': {
    Component: 'StudentApp',
    title: DEFAULT_TITLE,
    '/lesson_steps/:activeStep': {
      Component: 'StudentApp',
      permit: [
        'teacher',
        'principal',
        'district_admin',
        'curriculum_coordinator',
      ],
      title: 'Lesson Preview',
    },
    '/section/:section': {
      Component: 'WritingLesson',
      permit: [
        'teacher',
        'principal',
        'district_admin',
        'curriculum_coordinator',
      ],
      title: DEFAULT_TITLE,
      '/step/:step': {
        Component: 'WritingLesson',
        permit: [
          'teacher',
          'principal',
          'district_admin',
          'curriculum_coordinator',
        ],
        title: DEFAULT_TITLE,
      },
    },
  },
  '/signup': { Component: 'SignUpDialog', title: 'Sign Up' },
  '/users/password/edit': {
    Component: 'RecoverPassword',
    title: 'Recover Password',
  },
  '/users/password/new': {
    Component: 'NewPassword',
    title: 'New Password',
  },
  '/student': {
    '/skill_lessons/:id': {
      Component: 'CskLesson',
      title: 'Skills Lesson Student View',
    },
    '/student_assignments/:studentAssignmentId': {
      Component: 'StudentApp',
      title: DEFAULT_TITLE,
      '/lesson_steps/:activeStep': {
        Component: 'StudentApp',
        title: DEFAULT_TITLE,
      },
      '/section/:section': {
        Component: 'WritingLesson',
        title: DEFAULT_TITLE,
        '/step/:step': { Component: 'WritingLesson', title: DEFAULT_TITLE },
      },
    },
    '/student_assignments/:studentAssignmentId/work': {
      Component: 'FlexibleStudentAssignment',
      title: DEFAULT_TITLE,
    },
    '/student_assignments/:studentAssignmentId/work/*': {
      Component: 'FlexibleStudentAssignment',
      title: DEFAULT_TITLE,
    },
  },
  '/student_assignments/:studentAssignmentId': {
    Component: 'StudentApp',
    title: DEFAULT_TITLE,
    permit: [
      'teacher',
      'principal',
      'district_admin',
      'curriculum_coordinator',
    ],
    '/lesson_steps/:activeStep': {
      Component: 'StudentApp',
      title: 'Full Student Work',
    },
  },
  '/teacher': {
    '/assignments/:assignmentId': {
      '/graded_v2': {
        Component: 'ItemAnalysis',
        title: 'Item Analysis',
      },
      '/lesson_assessments/:lessonAssessmentId': {
        Component: 'GradingHeader',
        title: 'Quick score',
      },
    },
    '/classes': {
      Component: 'Classes',
      title: 'Classes Dashboard',
      '/:schoolClassId': {
        Component: 'ClassPlanner',
        title: 'Class Planner',
        '/assignments/:assignmentId': {
          Component: 'AssignmentDetail',
          title: 'Assignment Details',
        },
        '/planner': {
          Component: 'ClassPlanner',
          title: 'Class Planner',
        },
        '/roster': {
          Component: 'ClassRoster',
          title: 'Class Roster',
        },
      },
    },
    '/combined_classes': {
      '/:combinedClassId': {
        '/roster': {
          Component: 'CombinedClassRoster',
          title: 'Combined Class Roster',
        },
      },
    },
    '/collections': {
      Component: 'CurriculumRedirect',
      title: 'Curriculum',
      '/:subject': {
        Component: 'Curriculum',
        title: 'Curriculum',
        '/:collectionSlug': {
          Component: 'CurriculumDetail',
          title: 'Collection Details',
          '/groups/:flexibleLessonGroupId': {
            Component: 'FlexibleLessonGroupDetailsPage',
            title: 'Group Details',
            '/lessons/:flexibleLessonId': {
              Component: 'FlexibleLessonDetailsPage',
              title: 'Lesson Details',
            },
          },
          '/modules/:lessonModuleId': {
            Component: 'ModuleDetail',
            title: 'Module Details',
            '/lessons/:lessonId': {
              Component: 'LessonDetailPage',
              title: 'Lesson Details',
            },
            '/resources': {
              Component: 'Resources',
              title: 'Teacher Resources',
            },
          },
        },
      },
      '/assessments': {
        Component: 'LibraryAssessments',
        title: 'Library Assessments',
        '/benchmark_assessments': {
          Component: 'BenchmarkAssessments',
          title: 'Benchmark Assessments',
        },
        '/period/:periodType': {
          Component: 'PeriodDetails',
          title: 'Period Details',
        },
      },

      '/lessons': {
        Component: 'LessonsExplore',
        title: 'Lessons',
      },
      '/benchmark_assessments': {
        Component: 'BenchmarkAssessments',
        title: 'Benchmark Assessments',
      },
      '/lessons/:lessonId': {
        Component: 'LessonDetailPage',
        title: 'Lesson Details',
      },
      '/groups/:flexibleLessonGroupId': {
        Component: 'FlexibleLessonGroupDetailsPage',
        title: 'Group Details',
        '/lessons/:flexibleLessonId': {
          Component: 'FlexibleLessonDetailsPage',
          title: 'Lesson Details',
        },
      },
      '/modules/:lessonModuleId': {
        Component: 'ModuleDetail',
        title: 'Module Details',
        '/lessons/:lessonId': {
          Component: 'LessonDetailPage',
          title: 'Lesson Details',
        },
        '/resources': {
          Component: 'Resources',
          title: 'Teacher Resources',
        },
      },
      '/search': {
        Component: 'CurriculumSearch',
        title: 'Search Results',
      },
    },
    '/coordination': {
      Component: 'CurriculumCoordination',
      title: 'Curriculum Coordination',
    },
    '/lessons-and-units': {
      Component: 'LibraryThinkCERCAPro',
      title: 'ThinkCERCA',
      '/thinkcerca': {
        Component: 'LibraryThinkCERCAPro',
        title: 'ThinkCERCA',
      },
      '/skills': {
        Component: 'LibrarySkills',
        title: 'Direct Instruction and Skills Practice',
        '/:subject/:slug/:moduleId': {
          Component: 'LibrarySkillsDetailPage',
          title: 'Skills Collection Detail',
        },
      },
      '/browse': {
        Component: 'LibraryBrowse',
        title: 'Browse',
      },
      '/courses': {
        Component: 'LibraryCourses',
        title: 'Courses',
        '/:courseSlug': {
          Component: 'LibraryCourses',
          title: 'Courses',
          '/units/:unitId': {
            Component: 'LibraryUnitsPage',
            title: 'Course Unit',
            '/modules/:moduleId': {
              Component: 'LibraryUnitModules',
              title: 'Modules',
            },
          },
        },
      },
      '/units': {
        Component: 'LibraryUnits',
        title: 'Units',
        '/:unitId': {
          Component: 'LibraryUnitsPage',
          title: 'Unit',
          '/modules/:moduleId': {
            Component: 'LibraryUnitModules',
            title: 'Modules',
          },
        },
      },
    },
    '/core_curriculum': {
      '/grades': {
        Component: 'CoreCurriculumGrade',
        title: 'Core Curriculum Grade',
        '/:grade': {
          Component: 'CoreCurriculumGrade',
          title: 'Core Curriculum Grade',
          '/units/:unitPosition': {
            Component: 'CoreCurriculumUnit',
            title: 'Core Curriculum Unit',
            '/modules/:modulePosition': {
              Component: 'CoreCurriculumModule',
              title: 'Core Curriculum Module',
            },
            '/additional_lessons': {
              Component: 'CoreCurriculumAdditionalLessons',
              title: 'Additional Lessons',
            },
          },
        },
      },
    },
    '/assessments': {
      Component: 'LibraryAssessments',
      title: 'Library Assessments',
      '/benchmark_assessments': {
        Component: 'BenchmarkAssessments',
        title: 'Benchmark Assessments',
      },
      '/period/:periodType': {
        Component: 'PeriodDetails',
        title: 'Period Details',
      },
    },
    '/browse/lessons/:flexibleLessonId': {
      Component: 'FlexibleLessonDetailsPage',
      title: 'Lesson Details',
    },
    '/custom_lessons': {
      Component: 'CustomLessons',
      title: 'Custom Lessons Create',
      '/:id': {
        Component: 'CustomLessons',
        title: 'Custom Lessons Edit',
      },
    },
    '/grading': {
      '/lesson_assessments/:lessonAssessmentId': {
        Component: 'NewGrading',
        title: 'Rubric Score',
      },
    },
    '/insight': {
      Component: 'InsightRedirect',
      title: 'Insight Reports',
      '/institutions/:institutionId': {
        '/benchmarks': {
          Component: 'InsightBenchmark',
          legacyTitle: 'benchmark',
          title: 'Benchmark Writing Assessment Report',
        },
        '/class_usage_report': {
          Component: 'InsightClassUsage',
          legacyTitle: 'classUsage',
          title: 'Class Usage Report',
        },
        '/grade_usage_report': {
          Component: 'InsightGradeUsage',
          legacyTitle: 'gradeUsage',
          permit: ['principal', 'district_admin', 'curriculum_coordinator'],
          title: 'Grade Usage Report',
        },
        '/leveling': {
          Component: 'InsightLeveling',
          legacyTitle: 'leveling',
          title: 'Leveling Assessment Report',
        },
        '/performance': {
          Component: 'InsightPerformanceByGradeList',
          legacyTitle: 'performanceByGrade',
          title: 'Performance by Grade Report',
          permit: ['principal', 'district_admin', 'curriculum_coordinator'],
          '/grade/:gradeLevel': {
            Component: 'InsightPerformanceByGrade',
            legacyTitle: 'performanceByGrade',
            title: 'Performance by Grade Report',
            permit: ['principal', 'district_admin', 'curriculum_coordinator'],
          },
          '/school_classes': {
            Component: 'InsightPerformanceByClassList',
            legacyTitle: 'performanceByClass',
            title: 'Performance by Class Report',
            '/:schoolClassId': {
              Component: 'InsightPerformanceByClass',
              title: 'Performance by Class Report',
              legacyTitle: 'performanceByClass',
            },
          },
        },
        // this route is defunct, should remove
        '/skill_and_concept': {
          Component: 'InsightSkillAndConcept',
          legacyTitle: 'skillAndConcept',
          title: 'Insight Reports',
        },
      },
      '/unverified': {
        Component: 'InsightUnverified',
        title: DEFAULT_TITLE,
      },
    },
    '/institutions/:institutionId': {
      '/classes': {
        Component: 'InstitutionClasses',
        title: 'School Classes Dashboard',
      },
      '/assessments': {
        Component: 'InstitutionAssessments',
        title: 'School Assessments',
        '/period/:periodType': {
          Component: 'PeriodDetails',
          title: 'Period Details',
        },
      },
      '/roster': {
        Component: 'InstitutionRoster',
        title: 'School Roster',
      },
      '/students/:institutionUserId/edit': {
        Component: 'RosterManagement',
        title: 'Edit Student',
      },
      '/students/new': {
        Component: 'RosterManagement',
        title: 'Add Student',
      },
    },
    '/lesson_assessments/:lessonAssessmentId/edit': {
      Component: 'GradingHeader',
      title: 'Rubric Score',
    },
    // this route does not work, needs to be removed
    '/library/lessons': {
      Component: 'Curriculum',
      title: 'Curriculum',
    },
    '/skill_lessons/:id': {
      Component: 'CskLesson',
      title: 'Skills Lesson Teacher Preview',
      isTeacherPreview: true,
      '/student_preview': {
        Component: 'CskLesson',
        isStudentPreview: true,
        title: 'Skills Lesson Student Preview',
      },
    },
    '/subscription': {
      Component: 'SubscriptionPage',
      title: 'Subscription',
    },
    // defunct since we removed free users, no way to get here
    '/upgrade': { Component: 'UpgradeIndex', title: 'Upgrade' },
    '/reporting': {
      Component: 'Reporting',
      title: 'Reporting',
    },
  },
  '/user/profile': {
    Component: 'UserProfile',
    title: 'User Profile',
  },
  '/users/user_type': {
    Component: 'SignUpDialog',
    title: 'Sign Up Choose Account Type',
  },
  '/sitemap': {
    Component: 'Sitemap',
    title: 'Site Map',
  },
  '/preview/lesson/:id/*': {
    Component: 'PreviewFlexibleLesson',
    title: 'Preview Flexible Lesson',
  },
  '/pro_scored_classes': {
    Component: 'ProScoredClasses',
    title: 'Pro Scored Classes',
  },
  '/pro_scored_classes/:id': {
    Component: 'ProScoredClass',
    title: 'Pro Scored Class',
  },
  '/pro_scored_classes/:schoolClassId/modules/:moduleId': {
    Component: 'ProScoredClassModule',
    title: 'Pro Scored Class Module',
  },
  '/canvas/oauth2/token': {
    Component: 'Loading',
    title: 'Loading',
  },
}

export const { reducer, enhancer, middleware } = routerForBrowser({ routes })
